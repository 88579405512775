@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Bogimber";
    src: url("/public/fonts/bogimber/Bogimber.woff2") format("woff2");
}

:root {
    --font-bogimber: "Bogimber", sans-serif;
    --font-open-sans: "Open Sans", sans-serif;
}

* {
    scroll-behavior: smooth;
}

html {
    background-color: black;
}

.checkbox {
    position: absolute;
    display: block;
    z-index: 50 !important;
    opacity: 0;
    cursor: pointer;
}

.hamburger-lines {
    position: absolute;
    z-index: 40 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    cursor: pointer;
}

.hamburger-lines .line {
    display: block;
    width: 100%;
    height: 0.25rem;
    border-radius: 10px;
    background-color: white;
    z-index: 40 !important;
}

.hamburger-lines .line2 {
    width: 70%;
}

.hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.menu-items {
    display: none;
    flex-direction: column;
    overflow-y: hidden;
    background-color: black;
}

.menu-items ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 100%;
}

.menu-items ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
}

@keyframes slideInTop {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

input[type="checkbox"]:checked ~ .menu-items {
    display: flex;
    transform: translateX(0);
}

input[type="checkbox"]:checked ~ .menu-items {
    animation: slideInTop 0.5s;
}

input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(41.5deg);
}

input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
}

input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-41.5deg);
}

.poulet-img,
.room-img {
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.blend-effect {
    backdrop-filter: blur(2px);
}

.titles {
    font-family: var(--font-bogimber);
}

.text-font {
    font-family: var(--font-open-sans);
}

.house-bg {
    background-image: url("/src/images/ochsen_bg_1.webp");
    background-position: center;
    background-size: cover;
    min-height: 100svh;
}

.wood-1 {
    background-image: url("/src/images/wood_bg.webp");
    background-position: center;
    background-size: 100% 100%;
    min-height: 100vh;
}
